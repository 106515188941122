import config from '../config.json'

const url = config.BACKEND + "/tmps"

export async function createTemplate(payload) {
    const qr = await fetch(url + "/create", {
        method: "POST",
        cache: 'no-store',
        headers: {
            "Accept": "application/json",
            "Content-type": "application/json"
        },
        body: JSON.stringify(payload)
    })
    const res = await qr.json()
    return res
}

export async function renameTemplate(newName, tId, fId) {

    const payload = {
        newName: newName,
        tId: tId,
        fId: fId
    }

    const qr = await fetch(url + "/rename", {
        method: "POST",
        cache: 'no-store',
        headers: {
            "Accept": "application/json",
            "Content-type": "application/json"
        },
        body: JSON.stringify(payload)
    })
    const res = await qr.json()
    return res
}

export async function deleteTemplate(tId) {

    const payload = {
        tId: tId
    }

    const qr = await fetch(url + "/delete", {
        method: "POST",
        cache: 'no-store',
        headers: {
            "Accept": "application/json",
            "Content-type": "application/json"
        },
        body: JSON.stringify(payload)
    })
    const res = await qr.json()
    return res
}

export async function moveTemplate(tId, newParent) {
  
    const payload = {
        id: tId,
        newParent: newParent
    }

    const qr = await fetch(url + "/move", {
        method: "POST",
        cache: 'no-store',
        headers: {
            "Accept": "application/json",
            "Content-type": "application/json"
        },
        body: JSON.stringify(payload)
    })
    const res = await qr.json()
    return res
}

export async function getTemplates(parent) {
    const qr = await fetch(url + `?fId=${parent}`)
    const res = await qr.json()
    return res
}

export async function getTemplate(tempId) {
    const qr = await fetch(url + `/template?tId=${tempId}`)
    const res = await qr.json()
    return res
}

export async function setTemplateTexts(tId, texts) {
  
    const payload = {
        tId: tId,
        texts: texts
    }

    const qr = await fetch(url + "/set_texts", {
        method: "POST",
        cache: 'no-store',
        headers: {
            "Accept": "application/json",
            "Content-type": "application/json"
        },
        body: JSON.stringify(payload)
    })
    const res = await qr.json()
    return res
}