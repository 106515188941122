import React, {useState, useEffect} from 'react'
import Body from '../../components/Body/Body'
import left_arrow from './svg/left_arrow.svg'
import { ContextMenu } from '../../components/ContextMenu/ContextMenu'
import { getFolderPath, getFolders, getFoldersLastId, deleteFolder } from '../../services/fmService'
import { deleteTemplate, getTemplates, renameTemplate } from '../../services/templateService'
import {NotificationManager} from 'react-notifications';

import "./Templates.css"
import { Folder } from '../../components/Folder/Folder'
import { ReplaceFileWindow } from '../../components/ReplaceFileWindow/ReplaceFileWindow'
import { TemplateEditor } from '../../components/TemplateEditor/TemplateEditor'
import { Template } from '../../components/Template/Template'
import { ReplaceTemplateWindow } from '../../components/ReplaceTemplateWindow/ReplaceTemplateWindow'

export const Templates = () => {

    //Context menu
    const [showContext, setShowContext] = useState(false)
    const [contextX, setContextX] = useState(0)
    const [contextY, setContextY] = useState(0)
    const [contextContent, setContextContent] = useState([])

    //File System
    const [fId, setFId] = useState(1)
    const [fParents, setFParents] = useState([])
    const [folders, setFolders] = useState([])
    const [folderPath, setFolderPath] = useState("/")
    const [showReplace, setReplace] = useState(false)
    const [selectedFolder, setSelectedFolder] = useState(0)
    const [selectedNameFolder, setSelectedNameFolder] = useState("")

    //TemplateEditor
    const [showEditor, setShowEditor] = useState(false)
    const [editFile, setEditFile] = useState(false)

    //Templates
    const [templates, setTemplates] = useState([])
    const [showTemplateReplace, setShowTemplateReplace] = useState(false)
    const [selectedTemplate, setSelectedTemplate] = useState(0)
    const [selectedNameTemplate, setSelectedNameTemplate] = useState("")

    useEffect(() => {
        getFolders(fId).then(res => {
            setFolders(res.msg)
        })
        getTemplates(fId).then (res => {
            const tmps = res.msg.map(v => {
                v['rename'] = false
                return v
            })
            console.log(tmps)
            setTemplates(res.msg)
        })
        getFolderPath(fId).then(sres => {
            let fpath = sres['msg']
            if (fpath === "") {
                fpath = "/"
            }
            setFolderPath(fpath)
        })
    }, [fId])


    const createFolder = () => {
        setShowContext(false)
        setFolders(oldFolders => [...oldFolders, {id: 0, name: "", parent: 0}])
    }

    const showError = (msg) => {
        NotificationManager.error(msg, 'Ошибка')
    }

    const cancelCreateFolder = () => {
        setFolders(folders.filter(v => v.name !== ""))
    }

    const onFolderClick = (id, fparent) => {
        setFId(id)
        setFParents(old => [...old, fparent])
    }

    const closeContext = () => setShowContext(false)

    const closeReplace = () => setReplace(false)

    const closeReplaceTemplate = () => setShowTemplateReplace(false)

    const updateFolderId = (name) => {
        getFoldersLastId().then(res => {
            const maxId = res['msg']
            setFolders(folders.map(e => {
                if (e['id'] === 0) {
                    e['id'] = maxId
                    e['name'] = name
                    e['parent'] = fId
                    return e
                } else {
                    return e
                }
            }))
        })
    }

    const OnBackFolder = () => {
        if (fParents.length > 0) {
            const goId = fParents[fParents.length - 1]
            setFId(goId)
            setFParents(fParents.filter(v => v !== goId))
            
        }
    }

    const delFolder = (id) => {
        setShowContext(false)
        setFolders(folders.filter(v => v.id !== parseInt(id)))
        deleteFolder(id)
        deletFolder(id)
    }

    const renameFolder = (id) => {
        setShowContext(false)
        setFolders(folders.map(e => {
            if (e['id'] === parseInt(id)) {
                e['oldName'] = e['name']
                e['name'] = ""
                return e
            } else {
                return e
            }
        }))
    }

    const cancelRename = (id, name) => {
        setFolders(folders.map(e => {
            if (e['id'] === parseInt(id)) {
                e['name'] = name
                return e
            } else {
                return e
            }
        }))
    }

    const replaceFolder = (fId, fname) => {
        setReplace(true)
        setShowContext(false)
        setSelectedFolder(fId)
        setSelectedNameFolder(fname)
    }

    const replaceTemplate = (tId, tname) => {
        setShowTemplateReplace(true)
        setShowContext(false)
        setSelectedTemplate(tId)
        setSelectedNameTemplate(tname)
    }

    const deletFolder = (id) => {
        setFolders(folders.filter(v => v.id !== parseInt(id)))
    }

    const delTemplate = (id) => {
        setTemplates(templates.filter(v => v.id !== parseInt(id)))
    }

    const openEditorEdit = (selTemplate) => {
        setShowEditor(true)
        setEditFile(true)
        setSelectedTemplate(selTemplate)
    }

    const openEditor = () => {
        setShowEditor(true)
    }

    const closeEditor = () => {
        setShowEditor(false)
        setEditFile(false)
        setSelectedTemplate(0)
    }

    const startRenameTemplate = (tId) => {
        setTemplates(templates.map(v => {
            if (v['id'] === parseInt(tId)) {
                v['rename'] = true
                return v
            } else {
                return v
            }
        }))
        setShowContext(false)
    }

    const cancelRenameTemplate = (tId) => {
        setTemplates(templates.map(v => {
            if (v['id'] === parseInt(tId)) {
                v['rename'] = false
                return v
            } else {
                return v
            }
        }))
    }

    const finishRenameTemplate = (newName, tId) => {
        renameTemplate(newName, tId, fId).then(res => {
            setTemplates(templates.map(v => {
                if (v['id'] === parseInt(tId)) {
                    v['rename'] = false
                    v['name'] = newName
                    return v
                } else {
                    return v
                }
            }))
        })
    }

    const startDeleteTemplate = (tId) => {
        deleteTemplate(tId).then(res => {
            setTemplates(templates.filter(v => v.id !== parseInt(tId)))
            setShowContext(false)
        })
    }

    const addTemplate = (lastId, name) => {
        console.log(lastId, name, fId, false)
        setTemplates(oldTemplates => [...oldTemplates, {id: lastId, name: name, parent: fId, rename: false}])
    }

    //0 - empty
    //1 - folder
    const showNav = (e) => {
        setShowContext(false)
        e.preventDefault()
        setShowContext(true)
        setContextX(e.pageX)
        setContextY(e.pageY)
        if ((e.target.parentElement.className === "folder_main created") || (e.target.className === "folder_main created")) {
            const folderId = e.target.getAttribute("data-id")
            const folderName = e.target.getAttribute("data-name")
            setContextContent([
                {
                    "name": "Переименовать",
                    "func": renameFolder,
                    "args": [folderId]
                },
                {
                    "name": "Переместить",
                    "func": replaceFolder,
                    "args": [folderId, folderName]
                },
                {
                    "name": "Удалить",
                    "func": delFolder,
                    "args": [folderId]
                },
            ])
        } else if ((e.target.parentElement.className === "template_main") || (e.target.className === "template_main")) {
            const templateId = e.target.getAttribute("data-id")
            const templateName = e.target.getAttribute("data-name")
            setContextContent([
                {
                    "name": "Переименовать",
                    "func": startRenameTemplate,
                    "args": [templateId]
                },
                {
                    "name": "Редактировать",
                    "func": openEditorEdit,
                    "args": [templateId]
                },
                {
                    "name": "Переместить",
                    "func": replaceTemplate,
                    "args": [templateId, templateName]
                },
                {
                    "name": "Удалить",
                    "func": startDeleteTemplate,
                    "args": [templateId]
                },
            ])
        }else {

            setContextContent([
                {
                    "name": "Создать папку",
                    "func": createFolder,
                    "args": []
                },
                {
                    "name": "Создать шаблон",
                    "func": openEditor,
                    "args": []
                }
            ])
        }
    }

  return (
    <Body>
        <div id='templates_main'>
            <div id='templates_panel'>
                <img onClick={OnBackFolder} src={left_arrow} alt='left_arrow'/>
                <div id='templates_panel_path'>
                    <p>{folderPath}</p>
                </div>
            </div>
            <div id='templates_content'
                onContextMenu={(e) => showNav(e, 0)}
            >
                <div id='templates_files'>
                    {((folders.length < 1) && (templates.length < 1)) && <p style={{textAlign: "center", position: "absolute", right: "calc( 100vw / 2 )"}}>Здесь пока пусто</p> }
                    {
                        (folders.length > 0) && Object.values(folders).map(val => {
                            return(
                                <Folder
                                    name={val.name}
                                    oldName = {val.oldName}
                                    cancel={cancelCreateFolder}
                                    fId={fId}
                                    update={updateFolderId}
                                    showError={showError}
                                    onFolderClick={onFolderClick}
                                    idd = {val.id}
                                    fparent = {val.parent}
                                    cancelRename = {cancelRename}
                                />
                            )
                        })
                    }

{
                        (templates.length > 0) && Object.values(templates).map(val => {
                            return(
                                <Template
                                    id = {val.id}
                                    name = {val.name}
                                    rename = {val.rename}
                                    cancelRename = {() => cancelRenameTemplate(val.id)}
                                    finishRename = {(v) => finishRenameTemplate(v, val.id)}
                                />
                            )
                        })
                    }
                </div>
            </div>
            <ContextMenu 
                    show={showContext}
                    conX={contextX}
                    conY={contextY}
                    cont={contextContent}
                    close={closeContext}
                    
                />
            { showTemplateReplace && <ReplaceTemplateWindow
                close = {closeReplaceTemplate}
                folId = {selectedTemplate}
                folName = {selectedNameTemplate}
                deleteF = {delTemplate}
                showError = {showError}
            />}
            { showReplace && <ReplaceFileWindow
                close = {closeReplace}
                folId = {selectedFolder}
                folName = {selectedNameFolder}
                deleteF = {deleteFolder}
                showError = {showError}
            />}
            {(showEditor) && <TemplateEditor
                close = {closeEditor}
                fId = {fId}
                addTemplate = {addTemplate}
                editFile = {editFile}
                selTemplate = {selectedTemplate}
            />}
        </div>
    </Body>
  )
}
