import config from '../config.json'

const url = config.BACKEND + "/admin"

export async function confirmPass(pass) {
    const payload = {
        pass: pass
    }
    const qr = await fetch(url + "/confirm", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-type": "application/json"
        },
        body: JSON.stringify(payload)
    })
    const res = await qr.json()
    return res
}

export async function changePass(masPass, newPass) {
    const payload = {
        mastPass: masPass,
        newPass: newPass
    }
    const qr = await fetch(url + "/change-pass", {
        method: "POST",
        headers: {
            "Accept": "application/json",
            "Content-type": "application/json"
        },
        body: JSON.stringify(payload)
    })
    const res = await qr.json()
    return res
}