// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.folder_main {
    width: 80px;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border: none;
}

.folder_main.created:hover {
    background-color: rgba(53, 100, 255, 0.288);
    cursor: pointer;
}

.folder_main p{
    padding: 0;
    margin: 0;
}

.folder_main input{
    width: 70%;
}`, "",{"version":3,"sources":["webpack://./src/components/Folder/Folder.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,2CAA2C;IAC3C,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,SAAS;AACb;;AAEA;IACI,UAAU;AACd","sourcesContent":[".folder_main {\r\n    width: 80px;\r\n    height: 100px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    background-color: white;\r\n    border: none;\r\n}\r\n\r\n.folder_main.created:hover {\r\n    background-color: rgba(53, 100, 255, 0.288);\r\n    cursor: pointer;\r\n}\r\n\r\n.folder_main p{\r\n    padding: 0;\r\n    margin: 0;\r\n}\r\n\r\n.folder_main input{\r\n    width: 70%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
