import React, {useState, useEffect} from 'react'
import { getFolderPath, getFolders, moveFolder } from '../../services/fmService'
import useClickOutside from '../../hooks/useClickOutside'
import left_arrow from './svg/left_arrow.svg'
import folder from './svg/folder.svg'
import "./ReplaceFileWindow.css"


export const ReplaceFileWindow = (props) => {

    const [fId, setFId] = useState(1)
    const [fParents, setFParents] = useState([])
    const [folders, setFolders] = useState([])
    const [folderPath, setFolderPath] = useState("/")

    const handleClickOutside = () => {
        props.close()
    }

    const ref = useClickOutside(handleClickOutside);

    useEffect(() => {
        getFolders(fId).then(res => {
            setFolders(res.msg)
        })
        getFolderPath(fId).then(sres => {
            let fpath = sres['msg']
            if (fpath === "") {
                fpath = "/"
            }
            setFolderPath(fpath)
        })
    }, [fId])

    const OnBackFolder = () => {
        if (fParents.length > 0) {
            const goId = fParents[fParents.length - 1]
            setFId(goId)
            setFParents(fParents.filter(v => v !== goId))
            
        }
    }

    const onFolderClick = (id, fparent) => {
        setFId(id)
        setFParents(old => [...old, fparent])
    }

    const closeWindow = () => {
        props.close()
    }

    const onMoveFolderClick = () => {
        var finded = folders.find(v => v.name === props.folName)
        if (finded === undefined) {
            moveFolder(parseInt(props.folId), fId).then(res => {
                if ("msg" in res) {
                    props.deleteF(props.folId)
                    props.close()
                }
            })
        } else {
            props.showError(`В этой папке уже существует файл/папка с именем ${props.folName}`)
        }
        
    }

  return (
    <div id='replace_background'>
        <div ref={ref} id='replace_main'>
            <div id='replace_header'>
                <p>Переместить в ...</p>
            </div>
            <div id='replace_path'>
                <img onClick={OnBackFolder} src={left_arrow} alt="arrow" />
                <p>{folderPath}</p>
            </div>
            <div id='replace_content'>
                <div id='replace_panel'>
                    {(folders.length < 1) && <p id='replace_empty'>Пусто</p>}
                    {
                        (folders.length > 0) && Object.values(folders).map(v => {
                            if (v.id !== parseInt(props.folId)) {
                                return (
                                    <div onClick={() => onFolderClick(v.id, v.parent)} className='replace_folder'>
                                        <img src={folder} />
                                        <p>{v.name}</p>
                                    </div>
                                )
                            }
                        })
                    }
                </div>
                <div id='replace_button_container'>
                    <div onClick={closeWindow} className='replace_button'>
                        <p>Отмена</p>
                    </div>
                    <div onClick={onMoveFolderClick} className='replace_button'>
                        <p>Переместить</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
