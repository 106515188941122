import { Route, Routes, useLocation } from "react-router-dom";
import { Cookies, useCookies } from "react-cookie";
import {NotificationContainer} from 'react-notifications';
import { Login } from "./pages/Login/Login";
import { IndexPage } from "./pages/IndexPage/IndexPage";
import {Templates} from "./pages/Templates/Templates";
import { Fonts } from "./pages/Fonts/Fonts";
import 'react-notifications/lib/notifications.css'
import "./App.css"
import { Users } from "./pages/Users/Users";
import { confirmPass } from "./services/adminService";
import { useEffect, useState } from "react";
import Settings from "./pages/Settings/Settings";




function App() {

  const [cookies, setCookies] = useCookies(["user"])
  const [allowed, setAllowed] = useState(false)

  const location = useLocation()

  useEffect(() => {
    if (cookies.user) {
      confirmPass(cookies.user.toString()).then(res => {
        console.log(cookies.user)
        if (res['msg'].length < 1) {
          setAllowed(false)
        } else {
          setAllowed(true)
        }
      })
    } else {
      setAllowed(false)
    }
  }, [location])

  return (
    <>
      <Routes>
          <Route path="/" element={allowed ? <Users/> : <Login/>}/>
          <Route path="/users" element={allowed ? <Users/> : <Login/>}/>
          <Route path="/templates" element={allowed ? <Templates/> : <Login/>}/>
          <Route path="/fonts" element={allowed ? <Fonts/> : <Login/>}/>
          <Route path="/settings" element={allowed ? <Settings/> : <Login/>}/>
      </Routes>
      <NotificationContainer/>
    </>
  );
}

export default App;
