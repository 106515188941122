import React, {useState, useRef, useEffect} from 'react'
import './TemplateEditor.css'
import { TemplateText } from '../TemplateText/TemplateText'
import { TextEditorInput } from '../TextEditorInput/TextEditorInput'
import {NotificationManager} from 'react-notifications';
import { getFonts } from '../../services/fontService'
import { createTemplate, getTemplate, setTemplateTexts } from '../../services/templateService';

export const TemplateEditor = (props) => {
    
    const imageRef = useRef()

    const [name, setName] = useState("")
    const [photo, setPhoto ] = useState(null)
    const [photoUrl, setPhotoUrl ] = useState()
    const [photoBoundles, setPhotoBoundles] = useState({})
    const [texts, setTexts] = useState([])
    const [fonts, setFonts] = useState([])

    const [formValid, setFormValid] = useState(false)

    const [size, setSize] = useState();

    const onResize = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;

        setSize({
            width: width,
            height: height,
        });
    };


    function handleChange(e) {
        if (e.target.files[0]) {
            const file = e.target.files[0]
            if (file.name.endsWith('.jpg')){
                setPhoto(file);
                setPhotoUrl(URL.createObjectURL(e.target.files[0]));
            } else {
                setPhoto(null)
            }
        } else {
            setPhoto(null)
        }
        setTexts([])
    }

    useEffect(() => {
        setSize({
            width: window.innerWidth,
            height: window.innerHeight
        })
        getFonts().then(async res => {
            setFonts(res['msg'])
            let markup = ""
            let fams = []
            for (const k of res['msg']) {
                markup += [
                    '@font-face {\n',
                    '\tfont-family: \'', k['name'], '\';\n',
                    '\tfont-style: \'normal\';\n',
                    '\tfont-weight: \'normal\';\n',
                    '\tsrc: url(\'', `https://silverstandart100.ru/api/fonts/${k['name']}.ttf`, '\')', ` format('truetype');\n`,
                    '}\n'
                ].join('');
                fams.push(res['name'])
            }

            let style =  document.createElement('style');
            style.setAttribute('type', 'text/css');
            style.innerHTML = markup;
            document.head.appendChild(style)
            if (props.editFile) {
                setName(props.selTemplate)
                getTemplate(props.selTemplate).then(res => {
                    const data = res['msg']
                    setName(data.name)
                    setPhotoUrl("data:image/jpg;base64," + data.photo)
                    setPhoto("ss")
                })
            }
            
        })

        window.addEventListener("resize", onResize, false)

        return () => window.removeEventListener("resize", onResize, false)
        
    }, [])

    useEffect(() => {
        if (texts.length > 0) {
            
            const newBoundles = document.getElementById('TE_photo').getBoundingClientRect()
            const upTexts = texts.map((text) => {
                console.log(((photoBoundles.right - text.x) / photoBoundles.width) * newBoundles.width + newBoundles.right)
                return {
                    ...text,
                    x: newBoundles.right -((photoBoundles.right - text.x) / photoBoundles.width) * newBoundles.width, 
                    y: newBoundles.top - ((photoBoundles.top - text.y) / photoBoundles.height) * newBoundles.height,
                    size: (text.size / photoBoundles.width) * newBoundles.width
                };
            })
            setTexts(upTexts)
            setPhotoBoundles(newBoundles)
        }
        
    }, [size])

    const onNameChange = (e) => {
        const regex = /^[a-zA-Z0-9._-]+$/;
        if (regex.test(e.target.value) || (e.target.value === "")) {
            setName(e.target.value)
        }
    }

    const addText = () => {
        if (photo !== null) {
            const padding = 25
            const x1 = photoBoundles.left + window.scrollX + padding
            const x2 = photoBoundles.right + window.scrollX - padding
            const y1 = photoBoundles.top + window.scrollY + padding
            const y2 = photoBoundles.bottom + window.scrollY - padding

            const randX = Math.floor(Math.random() * (x2 - x1 + 1)) + x1;
            const randY = Math.floor(Math.random() * (y2 - y1 + 1)) + y1;

            setTexts(oldTexts => [...oldTexts, 
                { 
                    "id": texts.length,
                    "name": "text",
                    "x": randX,
                    "y": randY,
                    "color": "#000000",
                    "font": "arial",
                    "size": "18",
                    "bold": false,
                    "italic": false,
                    "underlined": false,
                    "textAlign": "left"
                }
            ])
        } else {
            NotificationManager.error("Сначала загрузите фото", "Ошибка")
        }
    }

    const onImgLoad = () => {
        setPhotoBoundles(document.getElementById('TE_photo').getBoundingClientRect())
        if (props.editFile) {
            const photo = document.getElementById('TE_photo').getBoundingClientRect()
            getTemplate(props.selTemplate).then(res => { 
                let texts = res['msg'].texts
                for (const k of texts) {
                    k['size'] = k['size'] * photo.width
                    k['x'] = k['x'] * photo.width + photo.left
                    k['y'] = k['y'] * photo.height + photo.top
                }
                setTexts(texts)
            })
        }
    }

    const onTextMove = (id, x, y) => {
        setTexts(texts.map(v => {
            if (v['id'] === id) {
                const newX = v['x'] + x
                const newY = v['y'] + y
                v['x'] = newX
                v['y'] = newY
                return v
            } else return v
        }))
    }

    const onTextNameChange = (e, id) => {
        setTexts(texts.map(v => {
            if (v.id === id) {
                v['name'] = e.target.value
            }
            return v
        }))
    }

    const deleteTextSymbol = (id) => {
        setTexts(texts.map(v => {
            if (v.id === id) {
                v['name'] = v['name'].substring(0, v['name'].length - 1)
            }
            return v
        }))
    }

    const onTextSizeChange = (value, id) => {
        const regex = /^[1-9][0-9]*$/;
        if (regex.test(value) || value === ""){
            setTexts(texts.map(v => {
                if (v.id === id) {
                    v['size'] = value
                }
                return v
            }))
        }
    }

    const decreaseTextSize = (id) => {
        setTexts(texts.map(v => {
            if (v.id === id) {
                v['size'] = parseInt(v['size']) - 1
            }
            return v
        }))
    }

    const onTextColorChange = (value, id) => {
        setTexts(texts.map(v => {
            if (v.id === id) {
                v['color'] = value
            }
            return v
        }))
    }

    const onTextFontChange = (value, id) => {
        setTexts(texts.map(v => {
            if (v.id === id) {
                v['font'] = value
            }
            return v
        }))
    }

    const onStyleChange = (style_name, id) => {
        setTexts(texts.map(v => {
            if (v.id === id) {
                v[`${style_name}`] = !v[`${style_name}`]
            }
            return v
        }))
    }

    const onAlignChange = (align, id) => {
        setTexts(texts.map(v => {
            if (v.id === id) {
                v[`textAlign`] = align
            }
            return v
        }))
    }

    const deleteText = (id) => {
        setTexts(texts.filter(v => v.id !== id))
    }

    const saveTemplate = (e) => {
        if (formValid) {
            if (!props.editFile) {
                const reader = new FileReader()
                reader.onload = (event) => {
                    let content = event.target.result;
                    content = content.split("base64,")[1]
                            
                    let updatedTexts = texts.map((text) => {
                        return {
                            ...text,
                            x: (text.x - photoBoundles.left) / photoBoundles.width,
                            y: (text.y - photoBoundles.top) / photoBoundles.height,
                            size: text.size / photoBoundles.width
                        };
                    });

                    console.log(updatedTexts)
                    
                    const finalPayload = {
                        name: name,
                        photo: content,
                        texts: updatedTexts,
                        fId: props.fId
                    }
                    createTemplate(finalPayload).then(res => {
                        if ("error" in res) {
                            NotificationManager.error(res['error'], "Ошибка")
                        } else {
                            props.addTemplate(res['lastId'], name)
                            setName("")
                            setPhoto(null)
                            setTexts([])
                            props.close()
                        }
                    })
                };
                    
                reader.readAsDataURL(photo);
            } else {
                let updatedTexts = texts.map((text) => {
                    return {
                        ...text,
                        x: (text.x - photoBoundles.left) / photoBoundles.width,
                        y: (text.y - photoBoundles.top) / photoBoundles.height,
                        size: text.size / photoBoundles.width
                    };
                });
                console.log(updatedTexts)
                setTemplateTexts(props.selTemplate, updatedTexts).then(res => {
                    setName("")
                    setPhoto(null)
                    setTexts([])
                    props.close()
                })
            }
        }
    }

    useEffect(() => {
        if ((name !== "") && (photo !== null) && (texts.length > 0)) {
            setFormValid(true)
        } else {
            setFormValid(false)
        }
    }, [name, photo, texts])

    

  return (
    <div id='TE_background'>
        <div id='TE_container'>
            <div id='TE_workspace'>
                {(photo) && <img draggable={false} ref={imageRef} id='TE_photo' src={photoUrl} onLoad={onImgLoad} />}
                {
                    Object.values(texts).map(v => {
                        return(
                            <TemplateText 
                                x = {v.x}
                                y = {v.y}
                                name = {v.name}
                                id = {v.id}
                                onMove = {onTextMove}
                                size = {v.size}
                                color = {v.color}
                                font = {v.font}
                                bold = {v.bold}
                                italic = {v.italic}
                                underlined = {v.underlined}
                                textAlign = {v.textAlign}
                                photoBoundles = {photoBoundles}
                                delSym = {() => deleteTextSymbol(v.id)}
                                decreaseSize = {() => {decreaseTextSize(v.id)}}
                            />
                        )
                    })
                }
            </div>
            <div id='TE_tools'>
                <p id='TE_tools_header'>Настройки</p>
                <div id='TE_template_name'>
                    <p>Имя:</p>
                    <input value={name} onChange={onNameChange}/>
                </div>
                { (!props.editFile) && <div id='TE_upload_photo'>
                    <p>Фото:</p>
                    <input accept='.jpg' onChange={handleChange} type='file' />
                </div>}
                <div id='TE_tools_panel'>
                    <div id='TE_tools_add'>
                        <p id='TE_tools_title' >Тексты</p>
                        <p id='TE_tools_add_button' onClick={addText} >Добавить</p>
                    </div>
                    <div id='TE_tools_texts'>
                        {(texts.length < 1) && <p id='TE_empty'>Пусто</p>}
                        {
                           Object.values(texts).map(v => {
                            return (
                                <TextEditorInput
                                    name = {v.name}
                                    onNameChange = {(e) => {onTextNameChange(e, v.id)}}
                                    size = {v.size}
                                    onSizeChange = {(val) => {onTextSizeChange(val, v.id)}}
                                    color = {v.color}
                                    onColorChange = {(val) => {onTextColorChange(val, v.id)}}
                                    font = {v.font}
                                    onFontChange = {(val) => {onTextFontChange(val, v.id)}}
                                    bold = {v.bold}
                                    italic = {v.italic}
                                    underlined = {v.underlined}
                                    onStyleChange = {(val) => {onStyleChange(val, v.id)}}
                                    fonts = {fonts}
                                    delete = {() => deleteText(v.id)}
                                    textAlign = {v.textAlign}
                                    onTextAlignChange = {(val) => onAlignChange(val, v.id)}
                                />
                            )
                           })
                        }
                    </div>
                </div>
                <div id='TE_buttons'>
                    <p onClick={props.close}>Отмена</p>
                    <p onClick={saveTemplate} className={`TE_save ${ (formValid) ? "" : "disabled" }`}>Сохранить</p>
                </div>
            </div>
        </div>
    </div>
  )
}
