import config from '../config.json'

const url = config.BACKEND + "/font"

export async function getFonts() {
    const qr = await fetch(url + `/`)
    const res = await qr.json()
    return res
}

export async function uploadFont(name, file) {
    const payload = {
        name: name,
        file: file
    }
    const qr = await fetch(url + "/upload", {
        method: "POST",
        cache: 'no-store',
        headers: {
            "Accept": "application/json",
            "Content-type": "application/json"
        },
        body: JSON.stringify(payload)
    })
    const res = await qr.json()
    return res
}

export async function deleteFont(name) {
    const payload = {
        name: name,
    }
    const qr = await fetch(url + "/delete", {
        method: "POST",
        cache: 'no-store',
        headers: {
            "Accept": "application/json",
            "Content-type": "application/json"
        },
        body: JSON.stringify(payload)
    })
    const res = await qr.json()
    return res
}