import React from 'react'
import Body from '../../components/Body/Body'

export const IndexPage = () => {
  return (
    <Body>
      Test
    </Body>
  )
}
