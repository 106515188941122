import React, {useState, useEffect} from 'react'
import "./Login.css"
import { confirmPass } from '../../services/adminService'
import {useNavigate} from 'react-router-dom'
import { useCookies } from "react-cookie";

export const Login = () => {
  const navigate = useNavigate()
  const [cookies, setCookies] = useCookies(["user"])

  const [password, setPassword] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  const onPassChange = (e) => {
    setPassword(e.target.value)
  }

  const checkPass = () => {
    if (password === "") {
      setErrorMessage("Неверный пароль")
      return
    }
    confirmPass(password).then((res) => {
      if (res['msg'].length < 1) {
        setErrorMessage("Неверный пароль")
      } else {
        setErrorMessage("")
        setCookies("user", password, { path: "/" });
        navigate('/')
      }
    })
  }

  return (
    <div id='login_container'>
        <div id='login_panel'>
            <h2>Добро пожаловать!</h2>
            { (errorMessage !== "") && <p id='login_error'>{errorMessage}</p>}
            <div className='login_input'>
                <label>Введите пароль</label>
                <input value={password} onChange={onPassChange} type='password'/>
            </div>
            <p onClick={checkPass} id='login_button'>Войти</p>
        </div>
    </div>
  )
}
