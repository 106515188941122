// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#login_container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#login_panel {
    width: 320px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid black;
    border-radius: 15px;
}

.login_input {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 80%;
}

.login_input input{
    height: 20px;
}

#login_button {
    border: 1px solid black;
    border-radius: 5px;
    padding: 2px 5px 2px 5px;
}

#login_button:hover {
    cursor: pointer;
}

#login_error {
    width: 80%;
    border: 1px solid #ff3333;
    background-color: #f4a4a4;
    color: white;
    margin: 0px 0px 5px 0px;
    padding: 5px 0px 5px 2px;
    border-radius: 5px;
}`, "",{"version":3,"sources":["webpack://./src/pages/Login/Login.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,wBAAwB;AAC5B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,UAAU;IACV,yBAAyB;IACzB,yBAAyB;IACzB,YAAY;IACZ,uBAAuB;IACvB,wBAAwB;IACxB,kBAAkB;AACtB","sourcesContent":["#login_container {\r\n    flex-grow: 1;\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n#login_panel {\r\n    width: 320px;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    border: 1px solid black;\r\n    border-radius: 15px;\r\n}\r\n\r\n.login_input {\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 10px;\r\n    width: 80%;\r\n}\r\n\r\n.login_input input{\r\n    height: 20px;\r\n}\r\n\r\n#login_button {\r\n    border: 1px solid black;\r\n    border-radius: 5px;\r\n    padding: 2px 5px 2px 5px;\r\n}\r\n\r\n#login_button:hover {\r\n    cursor: pointer;\r\n}\r\n\r\n#login_error {\r\n    width: 80%;\r\n    border: 1px solid #ff3333;\r\n    background-color: #f4a4a4;\r\n    color: white;\r\n    margin: 0px 0px 5px 0px;\r\n    padding: 5px 0px 5px 2px;\r\n    border-radius: 5px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
