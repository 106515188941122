import React from 'react'
import './User.css'

export const User = (props) => {
  return (
    <div className={`user_main ${(props.order) ? "order" : ""}`}>
        <p>{props.tgId}</p>
        <p>{props.username}</p>
        <p>{props.name}</p>
        {
            (props.order) && <div className='user_accept'>
                    <p onClick={() => props.mkDec(true)} >Принять</p>
                    <p onClick={() => props.mkDec(false)} >Отклонить</p>
                </div>
        }

        {
            (!props.order) && <div className='user_block'>
                    <p onClick={() => props.blUsr()} >Заблокировать</p>
                </div>
        }
    </div>
  )
}
