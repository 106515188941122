import React, {useState, useEffect, useRef} from 'react'
import { getFonts, uploadFont, deleteFont } from '../../services/fontService'
import {NotificationManager} from 'react-notifications';
import './Fonts.css'
import Body from '../../components/Body/Body'
import bin from './svg/bin.svg'

export const Fonts = () => {

    const [formValid, setFormValid] = useState(false)

    const [fontName, setFontName] = useState("")
    const [fontFile, setFontFile] = useState(null)

    const [fonts, setFonts] = useState([])

    const iFile = useRef(null)

    useEffect(() => {
        getFonts().then(res => {
            setFonts(res['msg'])
        })
    }, [])

    useEffect(() => {
        if ((fontName !== "") && (fontFile !== "")) {
            setFormValid(true)
        } else {
            setFormValid(false)
        }
    }, [fontName, fontFile])

    const onInputsChange = (e) => {
        switch (e.target.name) {
            case "name":
                const name_regex = /^[a-zA-Z0-9_\-\.\\\/ ]+$/
                if (name_regex.test(e.target.value) || e.target.value === "") setFontName(e.target.value)
                break;
            case "font":
                if (e.target.files[0]) {
                    const file = e.target.files[0]
                    if (file.name.endsWith('.ttf')){
                        console.log(file.name)
                        setFontFile(file);    

                    } else {
                        setFontFile(null)
                    }
                }
                break;
        }
    }

    const onAddClick = () => {
        if (formValid) {
            const reader = new FileReader()
            reader.onload = (event) => {
                let content = event.target.result;
                content = content.split("base64,")[1]
                        
                uploadFont(fontName, content).then(res => {
                    if ("error" in res) {
                        NotificationManager.error(res['error'], "Ошибка")
                    } else {
                        setFontFile(null)
                        setFontName("")
                        iFile.current.value = ""
                        setFonts(oldFonts => [...oldFonts, {id: res['lastId'], name: fontName, canDelete: 1}])
                    }
    
                })
            };
                
            reader.readAsDataURL(fontFile);
        }
    }

    const onDeleteFont = (name) => {
        deleteFont(name).then(res => {
            setFonts(fonts.filter(v => v.name !== name))
        })
    }

  return (
    <Body>
        <div id='fonts_main'>
            <div id='fonts_add'>
                <div className='fonts_add_input'>
                    <label>Имя шрифта:</label>
                    <input value={fontName} onChange={onInputsChange} name='name'/>
                </div>
                <div className='fonts_add_input'>
                    <label>Загрузите файл шрифта (ttf):</label>
                    <input ref={iFile} accept=".ttf" onChange={onInputsChange} type='file' name='font'/>
                </div>
                <div className={`fonts_add_button ${(formValid) ? "" : "disabled"}`}>
                    <p onClick={onAddClick}>Добавить</p>
                </div>
            </div>
            {
                Object.values(fonts).map(v => {
                    return(
                        <div className='fonts_font'>
                            <div className='fonts_font_row'>
                                <p>{v.name}</p>
                            </div>
                            <div className='fonts_font_row'>
                                
                            </div>
                            <div className={`fonts_font_row ${(v.canDelete === 0) ? "disabled" : ""}`}>
                                <img onClick={() => onDeleteFont(v.name)} src={bin} alt='cross'/>    
                            </div>
                        </div>
                    )
                })
            }
        </div>
    </Body>
  )
}
