import React, {useState, useEffect} from 'react'
import folder from './svg/folder.svg'
import { renameFolder, createFolder } from '../../services/fmService'
import stripAnsi from 'strip-ansi';

import "./Folder.css"

export const Folder = (props) => {

    const [newFolderName, setNewFolderName] = useState("")
    const [folderId, setFolderId] = useState(0)
    const [isCreated, setIsCreated] = useState(false)

    useEffect(() => {
        if (props.name !== "") {
            setIsCreated(false)
            setNewFolderName(props.name)
        }
    }, [props.name])

    useEffect(() => {
        if (props.name !== "") {
            setFolderId(props.idd)
        }

    }, [props.idd])

    const onFolderBlur = (e) => {
        if (folderId === 0){
            if (e.target.value === "") {
                props.cancel()
            } else {
                createFolder(e.target.value, props.fId).then(res => {
                    if ("msg" in res) {
                        setIsCreated(true)
                        props.update(newFolderName)
                    } else {
                        props.showError(res['error'])
                    }
                })
            }
        } else {
            if (e.target.value === "") {
                props.cancelRename(folderId, props.oldName)
                return
            }
            if ((newFolderName !== props.oldName) || (e.target.value === "")) {
                renameFolder(folderId, newFolderName).then(res => {
                    if ("msg" in res) {
                        setIsCreated(true)
                        props.cancelRename(folderId, newFolderName)
                    } else {
                        props.showError(res['error'])
                    }
                })
            } else {
                props.cancelRename(folderId, newFolderName)
            }
        }
    }

    const onFolderClick = (e) => {
        if (props.name !== "") {
            props.onFolderClick(folderId, props.fparent)
        }
    }

    const onFolderKeyDown = (e) => {
        if ((e.key === "Enter") && (newFolderName !== "")) {
            if (folderId === 0) {
                createFolder(e.target.value, props.fId).then(res => {
                    if ("msg" in res) {
                        setIsCreated(true)
                        props.update(newFolderName)
                    } else {
                        props.showError(res['error'])
                    }
                })
            } else {
                if (newFolderName !== props.oldName) {
                    renameFolder(folderId, newFolderName).then(res => {
                        if ("msg" in res) {
                            setIsCreated(true)
                            props.cancelRename(folderId, newFolderName)
                        } else {
                            props.showError(res['error'])
                        }
                    })
                } else {
                    props.cancelRename(folderId, newFolderName)
                }
            }
        }
    }

    const onFolderNameChange = (e) => {
        const folderNameRegex = /^[^\\/:*?"<>|]+$/;
        if (folderNameRegex.test(e.target.value) || (e.target.value === "")) {
            let txt = e.target.value
            console.log(txt)
            const rex = /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/ug;
            txt = txt.replace(rex, match => ``);
            console.log(txt)
            setNewFolderName(txt)
        }
    }

  return (
    <div data-id={props.idd} data-name={newFolderName} onClick={onFolderClick} className={`folder_main ${((props.name !== "") || (isCreated)) && "created"}`}>
        <img data-name={newFolderName} data-id={props.idd} src={folder} alt='folder'/>
        {
            ((props.name === "") && (!isCreated)) && <input
                autoFocus
                onBlur={onFolderBlur}
                onChange={onFolderNameChange}
                value={newFolderName}
                onKeyDown={onFolderKeyDown}
            />
        }
        {
            ((props.name !== "") || (isCreated)) && <p data-id={props.idd} data-name={newFolderName} >{newFolderName}</p>
        }
    </div>
  )
}
