// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html {
    min-height: 100%;

    display: flex;
    flex-direction: column;
}

body {
    margin: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

#root {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;;IAEhB,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,SAAS;IACT,YAAY;IACZ,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;AAC1B","sourcesContent":["html {\r\n    min-height: 100%;\r\n\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\nbody {\r\n    margin: 0;\r\n    flex-grow: 1;\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n#root {\r\n    flex-grow: 1;\r\n    display: flex;\r\n    flex-direction: column;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
