import React, {useState, useEffect} from 'react'
import './Users.css'
import Body from '../../components/Body/Body'
import { acceptUser, deleteUserOrder, getUsers, notAcceptUser } from '../../services/usersService'
import {User} from '../../components/User/User'
import { sendMessage } from '../../services/tgService'
import {NotificationManager} from 'react-notifications';

export const Users = () => {

    const [orders, setOrders] = useState([])
    const [users, setUsers] = useState([])

    const makeDecision = (answer, id) => {
        if (answer) {
            setUsers(oldUsers => [...oldUsers, orders.find(v => v.id === id)])
            acceptUser(id)
            const button = [{
                text: "В меню",
                callback_data: "back_menu"
            }]
            sendMessage("Поздравляем! Вашу заявку на пользование ботом одобрили!", id, button)
        } else {
            deleteUserOrder(id)
            sendMessage("К сожалению вам отказали с доступом к нашему боту", id, [])
        }
        setOrders(orders.filter(v => v.id != id))    
    }

    const blockUser = (id) => {
        notAcceptUser(id).then(res => {
            if ("error" in res) {
                NotificationManager.error(res['error'], "Ошибка")
            } else {
                setUsers(users.filter(v => v.id != id))
            }
        })
    }

    useEffect(() => {
        getUsers().then(res => {
            const tmp_users = res['msg']
            setOrders(tmp_users.filter(v => v.accepted !== 1))
            setUsers(tmp_users.filter(v => v.accepted !== 0))
        })
    }, [])

  return (
    <Body>
        <div id='users_main'>
            <div id='users_orders'>
                <div id='users_orders_header'>
                    <h3>Заявки</h3>
                </div>
                <div id='users_order_container'>
                    <div id='users_order_container_header'>
                        <p>TgId:</p>
                        <p>Юзернейм:</p>
                        <p>Имя:</p>
                        <p>Действия</p>
                    </div>
                    {
                        Object.values(orders).map(v => {
                            return (
                                <User 
                                    tgId = {v.id}
                                    name = {v.name}
                                    username = {v.username}
                                    order={true}
                                    mkDec = {(ans) => makeDecision(ans, v.id)}    
                                />
                            )
                        })
                    }
                </div>
            </div>
            <div id='users_list'>
                <div id='users_list_header'>
                    <h3>Активные пользователи</h3>
                </div>
                <div id='users_list_container'>
                    <div id='users_list_container_header'>
                        <p>TgId:</p>
                        <p>Юзернейм:</p>
                        <p>Имя:</p>
                        <p>Действия</p>
                    </div>
                    {
                        Object.values(users).map(v => {
                            return (
                                <User 
                                    tgId = {v.id}
                                    name = {v.name}
                                    username = {v.username}
                                    order={false}
                                    mkDec = {(ans) => makeDecision(ans, v.id)}    
                                    blUsr = {() => blockUser(v.id)}
                                />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    </Body>
  )
}
