// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#templates_main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

#templates_panel {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
}

#templates_panel img {
    width: 30px;
}

#templates_panel img:hover {
    cursor: pointer;
}

#templates_panel_path {
    flex: 2 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    border: 1px solid black;
}

#templates_panel_path p{
    flex: 1 1;
    padding: 5px 0px 5px 5px;
    margin: 0px;
}

#templates_panel_actions p{
    border: 1px solid black;
    margin: 0;
    padding: 2px 5px 2px 5px;
}

#templates_content {
    flex: 1 1;
    margin: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

#templates_files {
    flex: 1 1;
    gap: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 80px);
    grid-template-rows: repeat(auto-fill, 80px);
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/Templates/Templates.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,SAAO;IACP,aAAa;IACb,mBAAmB;IACnB,2BAA2B;IAC3B,uBAAuB;AAC3B;;AAEA;IACI,SAAO;IACP,wBAAwB;IACxB,WAAW;AACf;;AAEA;IACI,uBAAuB;IACvB,SAAS;IACT,wBAAwB;AAC5B;;AAEA;IACI,SAAO;IACP,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,8BAA8B;;AAElC;;AAEA;IACI,SAAO;IACP,SAAS;IACT,aAAa;IACb,8CAA8C;IAC9C,2CAA2C;IAC3C,uBAAuB;AAC3B","sourcesContent":["#templates_main {\r\n    flex-grow: 1;\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n#templates_panel {\r\n    margin-top: 5px;\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    gap: 20px;\r\n}\r\n\r\n#templates_panel img {\r\n    width: 30px;\r\n}\r\n\r\n#templates_panel img:hover {\r\n    cursor: pointer;\r\n}\r\n\r\n#templates_panel_path {\r\n    flex: 2;\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: flex-start;\r\n    border: 1px solid black;\r\n}\r\n\r\n#templates_panel_path p{\r\n    flex: 1;\r\n    padding: 5px 0px 5px 5px;\r\n    margin: 0px;\r\n}\r\n\r\n#templates_panel_actions p{\r\n    border: 1px solid black;\r\n    margin: 0;\r\n    padding: 2px 5px 2px 5px;\r\n}\r\n\r\n#templates_content {\r\n    flex: 1;\r\n    margin: 10px;\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n\r\n}\r\n\r\n#templates_files {\r\n    flex: 1;\r\n    gap: 20px;\r\n    display: grid;\r\n    grid-template-columns: repeat(auto-fill, 80px);\r\n    grid-template-rows: repeat(auto-fill, 80px);\r\n    justify-content: center;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
