import React, {useState, useEffect} from 'react'
import useClickOutside from '../../hooks/useClickOutside'

import "./ContextMenu.css"

export const ContextMenu = (prop) => {

    const handleClickOutside = () => {
        prop.close()
    }

    const ref = useClickOutside(handleClickOutside);

  return (
    <>
        {
            prop.show && <div
                ref={ref}
                id='context_main'
                style={{top: prop.conY + 10, left: prop.conX + 15}}
             >
                {
                    Object.values(prop.cont).map(v => {
                        return(
                            <div onClick={(e) => v.func(...v.args)} className='context_button'>
                                <p>{v.name}</p>
                            </div>
                        )
                    })
                }
            </div>
        }
    </>
  )
}
