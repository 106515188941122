import config from '../config.json'

const url = config.BACKEND + "/users"

export async function getUsers() {
    const qr = await fetch(url + "/users" , { 
        
        headers: { 
            "Accept": "application/json",
            "Content-type": "application/json"
        }
    })
    const res = await qr.json()
    return res
}

export async function acceptUser(id) {
    const payload = {
        tgId: id,
    }
    const qr = await fetch(url + "/accept", {
        method: "POST",
        cache: 'no-store',
        headers: {
            "Accept": "application/json",
            "Content-type": "application/json"
        },
        body: JSON.stringify(payload)
    })
    const res = await qr.json()
    return res
}

export async function notAcceptUser(id) {
    const payload = {
        tgId: id,
    }
    const qr = await fetch(url + "/not-accept", {
        method: "POST",
        cache: 'no-store',
        headers: {
            "Accept": "application/json",
            "Content-type": "application/json"
        },
        body: JSON.stringify(payload)
    })
    const res = await qr.json()
    return res
}

export async function deleteUserOrder(id) {
    const payload = {
        tgId: id,
    }
    const qr = await fetch(url + "/delete", {
        method: "POST",
        cache: 'no-store',
        headers: {
            "Accept": "application/json",
            "Content-type": "application/json"
        },
        body: JSON.stringify(payload)
    })
    const res = await qr.json()
    return res
}