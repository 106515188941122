// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.user_main {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1.5fr 2fr;
    text-align: center;
}



.user_accept {
    display: flex;
    flex-direction: row;
    justify-content: space-around;

}

.user_accept p{
    border: 1px solid black;
    padding: 2px 3px 2px 3px;
}

.user_accept p:hover{
    cursor: pointer;
}

.user_block {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.user_block p{
    border: 1px solid black;
    padding: 2px 3px 2px 3px;
}

.user_block p:hover{
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/User/User.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,0CAA0C;IAC1C,kBAAkB;AACtB;;;;AAIA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;;AAEjC;;AAEA;IACI,uBAAuB;IACvB,wBAAwB;AAC5B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,uBAAuB;IACvB,wBAAwB;AAC5B;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".user_main {\r\n    display: grid;\r\n    grid-template-columns: 1fr 1.5fr 1.5fr 2fr;\r\n    text-align: center;\r\n}\r\n\r\n\r\n\r\n.user_accept {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-around;\r\n\r\n}\r\n\r\n.user_accept p{\r\n    border: 1px solid black;\r\n    padding: 2px 3px 2px 3px;\r\n}\r\n\r\n.user_accept p:hover{\r\n    cursor: pointer;\r\n}\r\n\r\n.user_block {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-around;\r\n}\r\n\r\n.user_block p{\r\n    border: 1px solid black;\r\n    padding: 2px 3px 2px 3px;\r\n}\r\n\r\n.user_block p:hover{\r\n    cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
