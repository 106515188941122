import config from '../config.json'

const url = config.BACKEND + "/fm"

export async function getFolders(parent) {
    const qr = await fetch(url + `?fId=${parent}`)
    const res = await qr.json()
    return res
}

export async function getFolderPath(parent) {
    const qr = await fetch(url + `/path?fId=${parent}`)
    const res = await qr.json()
    return res
}

export async function getFoldersLastId() {
    const qr = await fetch(url + `/lastId`)
    const res = await qr.json()
    return res
}

export async function createFolder(name, parent) {
    const payload = {
        name: name,
        parent: parent
    }
    const qr = await fetch(url + "/create", {
        method: "POST",
        cache: 'no-store',
        headers: {
            "Accept": "application/json",
            "Content-type": "application/json"
        },
        body: JSON.stringify(payload)
    })
    const res = await qr.json()
    return res
}

export async function deleteFolder(id) {
    const payload = {
        id: id
    }
    const qr = await fetch(url + "/delete", {
        method: "POST",
        cache: 'no-store',
        headers: {
            "Accept": "application/json",
            "Content-type": "application/json"
        },
        body: JSON.stringify(payload)
    })
    const res = await qr.json()
    return res
}

export async function renameFolder(id, name) {
    const payload = {
        id: id,
        newName: name
    }
    const qr = await fetch(url + "/rename", {
        method: "POST",
        cache: 'no-store',
        headers: {
            "Accept": "application/json",
            "Content-type": "application/json"
        },
        body: JSON.stringify(payload)
    })
    const res = await qr.json()
    return res
}

export async function moveFolder(id, newParent) {
    const payload = {
        id: id,
        newParent: newParent
    }
    const qr = await fetch(url + "/move", {
        method: "POST",
        cache: 'no-store',
        headers: {
            "Accept": "application/json",
            "Content-type": "application/json"
        },
        body: JSON.stringify(payload)
    })
    const res = await qr.json()
    return res
}