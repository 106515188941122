import React, {useState, useRef, useEffect} from 'react'
import './TemplateText.css'

export const TemplateText = (props) => {
    
    const [isSelected, setIsSelected] = useState(false)

    const textRef = useRef(null)
    const spanRef = useRef(null)

    const onTextMouseDown = () => {
        setIsSelected(true)
    }

    const onTextMouseUp = () => {
        setIsSelected(false)
    }

    const onTextMouseMove = (e) => {
        if (isSelected) {
            if(e.stopPropagation) e.stopPropagation();
            if(e.preventDefault) e.preventDefault();
            e.cancelBubble=true;
            e.returnValue=false;
            const txtBoundles = spanRef.current.getBoundingClientRect()
            let moveX = e.movementX
            let moveY = e.movementY
            if ((txtBoundles.right + moveX < props.photoBoundles.right) && ((txtBoundles.left + moveX > props.photoBoundles.left)) && ((txtBoundles.top + moveY > props.photoBoundles.top)) && ((txtBoundles.bottom + moveY < props.photoBoundles.bottom))) {
                props.onMove(props.id, moveX, moveY)
            }

        }
    }

    useEffect(() => {
        const txtBoundles = textRef.current.getBoundingClientRect()
        if (txtBoundles.right >= props.photoBoundles.right) {
            props.delSym()
        }
    }, [props.name])

    useEffect(() => {
        const txtBoundles = textRef.current.getBoundingClientRect()
        if ((txtBoundles.right >= props.photoBoundles.right) || (txtBoundles.bottom >= props.photoBoundles.bottom)) {
            props.decreaseSize()
        }
    }, [props.size])

  return (
    <div ref={textRef}
        onMouseDown={onTextMouseDown}
        onMouseUp={onTextMouseUp}
        onMouseLeave={onTextMouseUp}
        onMouseMove={onTextMouseMove}
        className='TT_main'
        style={{
            "top": props.y,
            "left": props.x,
            "fontSize": `${props.size}px`,
            "color": props.color,
            justifyContent: props.textAlign,
            fontFamily: `'${props.font}'`,
            fontWeight: `${(props.bold) ? "bold" : "normal"}`,
            fontStyle: `${(props.italic) ? "italic" : "normal"}`,
            textDecoration: `${(props.underlined) ? "underline" : "none"}`
        }}
    >
        <span ref={spanRef}>{props.name}</span>
    </div>
  )
}
