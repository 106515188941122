import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './Body.css'
import burger from './svg/burger.svg'
import cross from './svg/cross.svg'
import { getUsers } from '../../services/usersService'

function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

const Body = (props) => {
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [headerOpen, setHeaderOpen] = useState(false)

  const [orders, setOrders] = useState([])

  useEffect(() => {

    getUsers().then(res => {
      const tmp_users = res['msg']
      setOrders(tmp_users.filter(v => v.accepted !== 1))
    })

    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    if (windowSize.innerHeight > 655) {
      setHeaderOpen(false)
    } else {
      setHeaderOpen(true)
    }
  }, [windowSize])

  return (
    <div id='body_main'>
      <div id='header_main'>
        <h3 onClick={() => navigate('/')}>Admin panel</h3>
        <div className={`header_links ${headerOpen ? "open": "close"}`}>
            <div id='header_users'>
              <Link to="/users">Пользователи</Link>
              { (orders.length > 0) && <div id='header_user_pushes'>
                <p>{orders.length}</p>
              </div>}
            </div>
            <Link to="/templates">Шаблоны</Link>
            <Link to="/fonts">Шрифты</Link>
            <Link to="/settings">Настройки</Link>
        </div>
        {windowSize.innerWidth < 655 && 
        <img onClick={() => setHeaderOpen(!headerOpen)} src={ headerOpen ? cross : burger } className={`burger ${headerOpen ? "open": "close"}`} />
        }
      </div>
      <div id='body_content'>
        {props.children}
      </div>
    </div>
  )
}

export default Body