// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#users_main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
    flex-grow: 1;
}

#users_orders {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    border-right: 0.1px solid black;
}

#users_list {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    
    
    border-left: 0.1px solid black;
}

#users_orders_header {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid black;
}

#users_list_header {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid black;
}

#users_order_container {
    flex: auto;
}

#users_order_container_header {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1.5fr 2fr;
    text-align: center;
    border-bottom: 1px solid black;
}

#users_list_container_header {
    display: grid;
    grid-template-columns: 1fr 1.5fr 1.5fr 2fr;
    text-align: center;
    border-bottom: 1px solid black;
}`, "",{"version":3,"sources":["webpack://./src/pages/Users/Users.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,SAAO;IACP,aAAa;IACb,sBAAsB;IACtB,+BAA+B;AACnC;;AAEA;IACI,SAAO;IACP,aAAa;IACb,sBAAsB;;;IAGtB,8BAA8B;AAClC;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,8BAA8B;AAClC;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,8BAA8B;AAClC;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,aAAa;IACb,0CAA0C;IAC1C,kBAAkB;IAClB,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,0CAA0C;IAC1C,kBAAkB;IAClB,8BAA8B;AAClC","sourcesContent":["#users_main {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n    position: relative;\r\n    flex-grow: 1;\r\n}\r\n\r\n#users_orders {\r\n    flex: 1;\r\n    display: flex;\r\n    flex-direction: column;\r\n    border-right: 0.1px solid black;\r\n}\r\n\r\n#users_list {\r\n    flex: 1;\r\n    display: flex;\r\n    flex-direction: column;\r\n    \r\n    \r\n    border-left: 0.1px solid black;\r\n}\r\n\r\n#users_orders_header {\r\n    width: 100%;\r\n    text-align: center;\r\n    border-bottom: 1px solid black;\r\n}\r\n\r\n#users_list_header {\r\n    width: 100%;\r\n    text-align: center;\r\n    border-bottom: 1px solid black;\r\n}\r\n\r\n#users_order_container {\r\n    flex: auto;\r\n}\r\n\r\n#users_order_container_header {\r\n    display: grid;\r\n    grid-template-columns: 1fr 1.5fr 1.5fr 2fr;\r\n    text-align: center;\r\n    border-bottom: 1px solid black;\r\n}\r\n\r\n#users_list_container_header {\r\n    display: grid;\r\n    grid-template-columns: 1fr 1.5fr 1.5fr 2fr;\r\n    text-align: center;\r\n    border-bottom: 1px solid black;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
