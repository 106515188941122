import React, {useState, useEffect} from 'react'
import './Settings.css'
import Body from '../../components/Body/Body'
import { changePass } from '../../services/adminService'
import {NotificationManager} from 'react-notifications';

const Settings = () => {

    const [masterPass, setMasterPass] = useState("")
    const [newPass, setNewPass] = useState("")
    const [validPass, setValidPass] = useState(false)

    useEffect(() => {
        if ((masterPass !== "") && (newPass !== "")) {
            setValidPass(true)
        } else {
            setValidPass(false)
        }
    }, [masterPass, newPass])

    const onPassChange = () => {
        if (validPass) {
            changePass(masterPass, newPass).then(res => {
                if ("error" in res) {
                    NotificationManager.error(res.error, "Ошибка")
                } else {
                    NotificationManager.success("Пароль успешно изменен!", "Поздравляем")
                    setNewPass("")
                    setMasterPass("")
                }
            })
        }
    }

  return (
    <Body>
        <div id='settings_main'>
            <div className='settings_container'>
                <p className='settings_container_label'>Изменение пароля</p>
                <div className='settings_container_inputs'>
                    <div className='settings_container_input'>
                        <label>Мастер-пароль:</label>
                        <input value={masterPass} onChange={(e) => setMasterPass(e.target.value)}/>
                    </div>
                    <div className='settings_container_input'>
                        <label>Новый пароль:</label>
                        <input value={newPass} onChange={(e) => setNewPass(e.target.value)}/>
                    </div>
                </div>
                <div className={`settings_container_actions ${validPass ? "" : "disabled"}`}>
                    <p onClick={onPassChange}>Изменить</p>
                </div>
            </div>
        </div>
    </Body>
  )
}

export default Settings