import React, {useState, useEffect} from 'react'
import './Template.css'
import template from './svg/template.svg'

export const Template = (props) => {

    const [tempName, setTempName] = useState("")

    useEffect(() => {
        setTempName(props.name)
    }, [props.name])

    const onNameChange = (e) => {
        const regex = /^[a-zA-Z0-9._-]+$/;
        if (regex.test(e.target.value)) {
            setTempName(e.target.value)
        }
    }

    const changeName = (e) => {
        if ((e.target.value === "") || (e.target.value === props.name)) {
            props.cancelRename()
        } else {
            const regex = /^[a-zA-Z0-9._-]+$/;
            if (regex.test(e.target.value)) {
                props.finishRename(e.target.value)
            }
        }
    }

    const onNameBlur = (e) => {
        changeName(e)
    }

    const onRenameKeyDown = (e) => {
        if (e.key === "Enter") {
            changeName(e)
        }
    }

  return (
    <div data-id={props.id} data-name={props.name} className={`template_main`}>
        <img data-id={props.id} data-name={props.name} src={template} alt='template'/>
        {(props.rename === false) && <p>{props.name}</p>}
        {(props.rename === true) && <input 
            value={tempName}
            autoFocus 
            onChange={onNameChange}
            onBlur={onNameBlur}
            onKeyDown={onRenameKeyDown}
        />}
    </div>
  )
}
