// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TT_main {
    display: flex;
    position: fixed;
    z-index: 2000; /* firefox */
   -webkit-user-select: none; /* Safari */ /* IE*/
   user-select: none; /* Standard syntax */
   width: 1px;
}

.TT_main:hover {
    cursor: move;
}`, "",{"version":3,"sources":["webpack://./src/components/TemplateText/TemplateText.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,eAAe;IACf,aAAa,EACU,YAAY;GACpC,yBAAyB,EAAE,WAAW,EACf,MAAM;GAC7B,iBAAiB,EAAE,oBAAoB;GACvC,UAAU;AACb;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".TT_main {\r\n    display: flex;\r\n    position: fixed;\r\n    z-index: 2000;\r\n    -moz-user-select:none; /* firefox */\r\n   -webkit-user-select: none; /* Safari */\r\n   -ms-user-select: none; /* IE*/\r\n   user-select: none; /* Standard syntax */\r\n   width: 1px;\r\n}\r\n\r\n.TT_main:hover {\r\n    cursor: move;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
