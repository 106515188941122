import config from '../config.json'

const url = `https://api.telegram.org/bot${config.BOT_TOKEN}/sendMessage`

export async function sendMessage(msg, uId, button) {

    const payload = {
        chat_id: uId,
        text: msg
    }

    if (button.length > 0) {
        payload['reply_markup'] = {
            inline_keyboard: [button]
        }
    }

    const qr = await fetch(url, {
        method: "POST",
        cache: 'no-store',
        headers: {
            "Accept": "application/json",
            "Content-type": "application/json"
        },
        body: JSON.stringify(payload)
    })
    const res = await qr.json()
    return res
}