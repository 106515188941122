import React, {useState, useEffect} from 'react'
import {HexColorPicker} from 'react-colorful'
import useClickOutside from '../../hooks/useClickOutside'
import './TextEditorInput.css'
import arrow_down from './svg/arrow_down.svg'
import arrow_up from './svg/arrow_up.svg'
import bold from './svg/bold.svg'
import italic from './svg/italic.svg'
import underlined from './svg/underlined.svg'
import bin from './svg/bin.svg'
import text_left from './svg/text_left.svg'
import text_center from './svg/text_center.svg'
import text_right from './svg/text_right.svg'
import pipette from './svg/pipette.svg'
import { EyeDropper, useEyeDrop } from 'react-eyedrop'

export const TextEditorInput = (props) => {


  const [colors, pickColor, cancelPickColor] = useEyeDrop({
    once: true,
    cursorActive: "pointer",
    onPickStart: () => {
      setPickingColor(true)
      console.log("start")
    },
    onPickEnd: () => {
      setPickingColor(false)
      console.log("end")
    },
    onPickCancel: () => {
      setPickingColor(false)
      console.log("cancel")
    }
  })
  const [showColor, setShowColor] = useState(false)

  const [fonts, SetFonts] = useState([])
  const [showFonts, setShowFonts] = useState(false)

  const [pickingColor, setPickingColor] = useState(false)


  const handleFontsClickOutside = () => {
    setShowFonts(false)
  }

  const fontsRef = useClickOutside(handleFontsClickOutside);

  useEffect(() => {
    SetFonts(props.fonts)
  }, [])

  const handleClickOutside = () => {
    if (!pickingColor) {
      setShowColor(false)
    }
  }

  const handleChangeColor = ({ rgb, hex }) => {
    setPickingColor(false)
    props.onColorChange(hex)
  }

  const pippeteCheckbox = () => {
    return (
      <img src={pipette} alt='pipette'/>
    )
  }

  const ref = useClickOutside(handleClickOutside);

  const onColorInputChange = (e) => {
    props.onColorChange(e.target.value.replace(/[^0-9a-zA-Z#]/g, ''))
  }

  return (
    <div className='TEI_main'>
        <input className='TEI_name' value={props.name} onChange={props.onNameChange}/>
        <div className='TEI_font'>
          <div onClick={() => setShowFonts(!showFonts)} className='TEI_font_panel'>
            <p>{props.font}</p>
            <img src={showFonts ? arrow_up : arrow_down} alt='arrow_down'/>
          </div>
          <div ref={fontsRef} className={`TEI_font_list ${showFonts ? 'opened' : 'closed'}`}>
            {Object.values(fonts).map(v => {
              return (
                <div onClick={() => {setShowFonts(false); props.onFontChange(v.name)}}>
                  <p>{v.name}</p>
                </div>
              )
            })}
          </div>
        </div>
        <div onClick={() => props.delete()} className='TEI_delete'>
          <img src={bin} alt='bin'/>
        </div>
        <div className='TEI_size'>
          <span onClick={() => (props.size > 1) && props.onSizeChange(parseInt(props.size) - 1)}>-</span>
          <input onBlur={(e) => (e.target.value === "") && props.onSizeChange(12)} onChange={(e) => props.onSizeChange(e.target.value)} value={props.size} />
          <span onClick={() => props.onSizeChange(parseInt(props.size) + 1)}>+</span>
        </div>
        <div className='TEI_style'>
            <div onClick={() => props.onStyleChange('bold')} className={`TEI_style_selector${ (props.bold) ? ' selected' : ''}`}>
              <img src = {bold} alt = 'bold'/>
            </div>
            <div onClick={() => props.onStyleChange('italic')} className={`TEI_style_selector${ (props.italic) ? ' selected' : ''}`}>
              <img src = {italic} alt = 'italic'/>
            </div>
            <div onClick={() => props.onStyleChange('underlined')} className={`TEI_style_selector${ (props.underlined) ? ' selected' : ''}`}>
              <img src = {underlined} alt = 'underlined'/>
            </div>
        </div>
        <div className='TEI_align'>
            <div onClick={() => props.onTextAlignChange("left")} className={`TEI_align_selector ${ (props.textAlign === "left" ) ? "selected" : ""}`}>
              <img src={text_left} alt='text_left'/>
            </div>
            <div onClick={() => props.onTextAlignChange("center")} className={`TEI_align_selector ${ (props.textAlign === "center" ) ? "selected" : ""}`}>
            <img src={text_center} alt='text_center'/>
            </div>
            <div onClick={() => props.onTextAlignChange("right")} className={`TEI_align_selector ${ (props.textAlign === "right" ) ? "selected" : ""}`}>
            <img src={text_right} alt='text_right'/>
            </div>
        </div>
        <div className='TEI_color'>
          <div style={{backgroundColor: props.color}} className='TEI_color_selector' onClick={() => setShowColor(!showColor)} >
            
          </div>
          {(showColor === true) && <div ref={ref} className='TEI_color_picker'>
              <HexColorPicker color={props.color} onChange={(v) => props.onColorChange(v)} />
              <div className={`TEI_color_input ${(pickingColor) && 'picking'}`}>
                <input value={props.color} onChange={onColorInputChange} />
                <div onClick={() => setPickingColor(true)}><EyeDropper wrapperClasses={`colorpick_wraper ${(pickingColor) && "active"}`} buttonClasses="colorpick" onChange={handleChangeColor}/></div>
              </div>
            </div>
            }
        </div>
    </div>
  )
}
