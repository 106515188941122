// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#context_main {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 2px;
    border: 1px solid black;
    padding: 5px 2px 5px 2px;
    background-color: white;
}

.context_button {
    padding: 1px 2px 1px 2px;
}

.context_button:hover {
    background-color: rgb(221, 221, 221);
    cursor: pointer;
}

.context_button p{
    margin: 0;
    padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/components/ContextMenu/ContextMenu.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,sBAAsB;IACtB,QAAQ;IACR,uBAAuB;IACvB,wBAAwB;IACxB,uBAAuB;AAC3B;;AAEA;IACI,wBAAwB;AAC5B;;AAEA;IACI,oCAAoC;IACpC,eAAe;AACnB;;AAEA;IACI,SAAS;IACT,UAAU;AACd","sourcesContent":["#context_main {\r\n    position: absolute;\r\n    display: flex;\r\n    flex-direction: column;\r\n    gap: 2px;\r\n    border: 1px solid black;\r\n    padding: 5px 2px 5px 2px;\r\n    background-color: white;\r\n}\r\n\r\n.context_button {\r\n    padding: 1px 2px 1px 2px;\r\n}\r\n\r\n.context_button:hover {\r\n    background-color: rgb(221, 221, 221);\r\n    cursor: pointer;\r\n}\r\n\r\n.context_button p{\r\n    margin: 0;\r\n    padding: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
